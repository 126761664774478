
import '@/assets/css/iframeStyle.scss';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AddCustomer from "./component/AddCustomer.vue";
import InviteDialog from "./component/InviteDialog.vue";
import FollowDialog from "./component/followDialog.vue";
import UnboundDialog from "./component/unboundDialog.vue";
import CustomerDetail from "./component/CustomerDetail.vue";
import { copyGetParam, verifyPhone } from '@/utils/utils';

@Component({
    name: "PersonReportCustomer",
    components: {
        AddCustomer,
        InviteDialog,
        CustomerDetail,
        FollowDialog,
        UnboundDialog,
    },
})
export default class PersonReportCustomer extends Vue {
    private loading: boolean = false;
    private height: any = 'calc(100vh - 280px)';
    private contentTitle: string = "报备客户";
    private activePage: number = 1; // 1、主页面；2、添加报备客户；3、报备用户详情；
    private searchParam: any = {
        pageSize: 10,
        pageNo: 1,
        name: '',
        mobile: '',
        statusList: '',
        followRecord: '',
    };
    private pageNo: number = 1;
    private pageSize: number = 10;
    private total: number = 0;
    private tableData = [];
    private unboundDialog: boolean = false;
    private followDialog: boolean = false;
    private invitationDialog: boolean = false;
    private currentRow: any = {};
    private gotoType: string = '';
    @Prop({
        type: Object,
        default: {},
    }) private companyInfo!: any; // 接收父组件传过来的值
    private created() {
        this.$nextTick( () => {
            this.getCustomerList();
        });
    }
    @Emit('goBack')
    private goBack() {
        return true;
    }
    private mounted() {
        this.height = window.innerHeight - 295;
        window.onresize = () => {
            return (() => {
                this.height = window.innerHeight - 295;
            })();
        };
    }
    private changeMobileNum() {
        this.searchParam.mobile = this.searchParam.mobile.replace(/[^\d]/g, '');
    }
    private getCustomerList() {
        this.loading = true;
        this.tableData = [];
        let paramData: any = {};
        paramData = copyGetParam(this.searchParam);
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/company/${this.companyInfo.companyId}/customer`)
        .then((res: any) => {
            this.tableData = res.list;
            this.total = res.total;
            this.loading = false;
        }).catch(() => {
            this.total = 0;
            this.loading = false;
        });
    }
    private onSearch() {
        this.total = 0;
        this.searchParam.pageNo = 1;
        this.searchParam.pageSize = 10;
        this.getCustomerList();
    }
    private resetHandle(formName: any) {
        this.searchParam = {
            name: '',
            mobile: '',
            statusList: '',
            followRecord: '',
        };
        this.onSearch();
    }
    private handleSizeChange(val: number) {
        this.searchParam.pageSize = val;
        this.getCustomerList();
    }
    private handleCurrentChange(val: number) {
        this.searchParam.pageNo = val;
        this.getCustomerList();
    }
    private detailClick(row: any) {
        this.currentRow = row;
        this.activePage = 3;
    }
    private deleteClick(row: any) {
        this.$confirm(`确定要删除报备企业“${row.name}”吗？`, '删除', {
            confirmButtonText: '确定删除',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
        }).then(() => {
            // 确定
            this.currentRow = row;
            this.deleteRequest();
        }).catch(() => {
            // 取消
        });
    }
    private deleteRequest() {
        const paramData: any = {};
        paramData.id = this.currentRow.id;
        this.$httpService.deleteData(paramData,
            `/apiProxy/api/frontend/company/${this.companyInfo.companyId}/customer/${this.currentRow.id}`)
        .then((res: any) => {
            this.$message.success('删除成功');
            this.getCustomerList();
        });
    }
    private inviteClick(row: any) {
        this.invitationDialog = true;
    }
    private followClick(row: any) {
        this.currentRow = row;
        this.followDialog = true;
    }
    private unboundClick(row: any) {
        this.currentRow = row;
        this.unboundDialog = true;
    }
    private againSubmitClick(row: any) {
        this.currentRow = row;
        this.activePage = 2;
    }
    private propAgain() {
        this.activePage = 2;
    }
    private addCustomer() {
        this.currentRow = {};
        this.activePage = 2;
    }
    private propBack(value: boolean) {
        if (this.gotoType === 'project' && value === false) {
            this.$router.push('/company/projectList').catch((err) => err);
        } else if (this.gotoType === 'authorize' && value === false) {
            this.$router.push('/company/authorize').catch((err) => err);
        } else if (this.gotoType === 'ecology') {
            this.$router.push('/company/ecoPartner').catch((err) => err);
        } else {
            this.activePage = 1;
            if (value) {
                this.getCustomerList();
            }
        }
    }
    private unboundClose(num: number) {
        if (num === 2) {
            this.getCustomerList();
        }
        this.unboundDialog = false;
    }
    private followClose(num: number) {
        if (num === 2) {
            this.getCustomerList();
        }
        this.followDialog = false;
    }
    private invitationClose() {
        this.invitationDialog = false;
    }
}
