
import '@/assets/css/iframeStyle.scss';
import { Component, Vue } from 'vue-property-decorator';
import CompanyCard from "./component/CompanyCard.vue";
import SystemCard from "./component/SystemCard.vue";
import ExperienceCard from "./component/ExperienceCard.vue";
import CarouselCard from "./component/CarouselCard.vue";
import HelpCard from "./component/HelpCard.vue";
import HotProductCard from "./component/HotProductCard.vue";
import ReportCustomer from "../reportCustomer/index.vue";

@Component({
    name: "PersonHome",
    components: {
        CompanyCard,
        SystemCard,
        ExperienceCard,
        CarouselCard,
        HelpCard,
        HotProductCard,
        ReportCustomer,
    },
})
export default class PersonHome extends Vue {
    private contentTitle: string = "个人中心";
    private baseUrlIframe = process.env.VUE_APP_URL;
    private activePage: number = 1;
    private iframeRefresh: boolean = true;
    private user: any = {};  // 用户信息
    private userInfo: any = {};
    private bindCompany: any = [];   // 绑定企业信息
    private joinCompany: any = [];   // 已加入的企业
    private createdCompany: any = {};   // 同一账号企业信息
    private companyInfo: any = {}; // 企业信息

    private created() {
        this.getPersonInfo();
        this.getBelongCompany();
    }
    private getPersonInfo() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/individual/profile')
        .then((res: any) => {
            this.userInfo = res;
        });
    }
    // 获取加入的企业
    private getBelongCompany() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/individual/company/belongs')
        .then((res: any) => {
            this.joinCompany = res.filter((item: any) => {
                if (item.createFlag === 1) {
                    this.createdCompany = item;
                }
                return item.joinFlag === 1;
            });
            this.bindCompany = res.filter((item: any) => {
                return item.createFlag !== 1;
            });
        });
    }
    // 刷新体验列表
    private remoteExperience() {
        const experienceCard: any = this.$refs.experienceCard;
        experienceCard.initData();
    }
    // 报备客户
    private goReportCustomer(row: any) {
        this.companyInfo = Object.assign({}, row);
        this.activePage = 2;
    }
}
