
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
  name: 'SearchCrm',
})

export default class SearchCrm extends Vue {
    private companyName: string = '';
    private contentList: any = [];
    private loading: boolean = false;
    @Prop({
        type: Boolean,
        default: false,
    }) private visible!: boolean; // 接收父组件传过来的值
    @Prop({
        type: String,
        default: '',
    }) private companyId!: string; // 接收父组件传过来的值
    // 给父组件传值
    @Emit('handleClose')
    private closeTodo(obj: any): any {
        return obj;
    }
    private cancelClose() {
        this.closeTodo({
            handleType: 0,
        });
    }
    private searchHandle() {
        if (this.companyName === '') {
            this.$message.warning('请输入公司名称！');
            return;
        }
        if (this.companyName && this.companyName.length < 2) {
            this.$message.warning('请至少输入2位公司名称！');
            return;
        }
        const paramData: any = {};
        paramData.name = this.companyName;
        if (this.companyId !== '') {
            paramData.companyId = this.companyId;
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/company/customer/crm/company/list')
        .then((res: any) => {
            this.contentList = [];
            if (res && res.length > 0) {
                res.forEach((item: any) => {
                    item.companyName = item.name.replace(this.companyName, `<span class="blur">${this.companyName}</span>`);
                    this.contentList.push(item);
                });
            }
        }).catch((err: any) => {
            this.contentList = [];
        });
    }
    private iteClick(item: any) {
        item.handleType = 1;
        this.closeTodo(item);
    }
}
