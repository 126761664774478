
import { Component, Vue } from 'vue-property-decorator';
import { encryptedData } from '@/utils/encrypt';
import FooterLogin from '@/components/FooterLogin/index.vue';

@Component({
    name: 'bindMc',
    components: {
        FooterLogin,
    },
})
export default class BindMc extends Vue {
    private userName: string = '';
    private password: string = '';
    private useInfo: any = {}; // 用户信息
    private mcInfo: any = {}; // mc信息
    private mcMask: string = '';

    private created() {
        if (this.$route.fullPath.indexOf('mcMask=') > -1) {
            this.mcMask = this.$route.query.mcMask + '';
        }
        this.$nextTick(() => {
            this.getUserInfo();
            this.getMcInfo();
        });
    }
    private changeType(e: any) {
        e.srcElement.type = 'password';
    }
    private getUserInfo() {
        // 获取当前用户信息
        this.$httpService.getData({}, '/apiProxy/api/frontend/users/current').then((res: any) => {
            this.useInfo = res;
        });
    }
    private getMcInfo() {
        // 获取绑定页MC信息
        const paramData: any = {};
        paramData.mcMask = this.mcMask;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/mc/bind/info').then((res: any) => {
            this.mcInfo = res;
        });
    }
    private checkBind() {
        if (this.userName === '') {
            this.$message.error('请输入应用用户名');
            return;
        }
        if (this.password === '') {
            this.$message.error('请输入应用密码');
            return;
        }
        // 绑定MC账号密码检查
        const paramData: any = {};
        paramData.userName = this.userName;
        paramData.password = encryptedData(this.password);
        paramData.mcMask = this.mcMask;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/mc/bind/check').then((res: any) => {
            if (res.bind) {
                this.$confirm('该MC账号已绑定摩尔云用户，是否修改？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.handleBind();
                }).catch(() => {
                    // ...
                });
            } else {
                this.handleBind();
            }
        });
    }
    private handleBind() {
        // 绑定MC账号密码
        const paramData: any = {};
        paramData.userName = this.userName;
        paramData.password = encryptedData(this.password);
        paramData.mcMask = this.mcMask;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/mc/bind').then((res: any) => {
            this.$alert('绑定成功', '提示', {
                showClose: false,
                confirmButtonText: '确定',
                callback: (action) => {
                    window.location.href = res.url;
                },
            });
        });
    }
}
