
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'InviteDialog',
})
export default class InviteDialog extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private InviteFlag!: boolean; // 接收父组件传过来的值
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Watch('InviteFlag') // 监听父组件传过来的值
    private InviteFlagFun(value: boolean) {
        this.showDialog = value;
    }
    private copyUrl() {
        const domUrl = document.createElement("input");
        const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
        domUrl.value = process.env.VUE_APP_URL + baseUrl + '/register';
        domUrl.id = "createDom";
        document.body.appendChild(domUrl);
        domUrl.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        const createDom: any = document.getElementById("createDom");
        createDom.parentNode.removeChild(createDom);
        this.$message({
            message: '复制成功',
            type: 'success',
        });
        this.closeHandleTodo();
    }
    private invitationClose() {
        this.showDialog = false;
        this.closeHandleTodo();
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeHandleTodo() {
        return true;
    }
}
