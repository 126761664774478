
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: "CarouselCard",
})
export default class CarouselCard extends Vue {
    @Prop(String) private height!: string;
    private baseUrlIframe = process.env.VUE_APP_URL;
    private swiperImage: any = [];  // 滚动屏图片

    private created() {
        this.getImage();
    }
    private getImage() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/banners/ucenter')
        .then((res: any) => {
            this.swiperImage = res;
        });
    }
    private openUrl(item: any) {
        // 点击事件上报
        this.$httpService.postData({}, `/apiProxy/api/frontend/banners/${item.id}/click`);
        // 跳转
        if (item.link) {
            if (item.openType === 1) {  // 打开新窗口
                window.open(item.link, '_blank');
            } else {    // 当前窗口打开
                window.open(item.link, '_self');
                // this.$router.push({ path: item.link }).catch((err) => err);
            }
        }
    }
}
