
import router from '@/router';
import { Component, Vue, Prop } from 'vue-property-decorator';
import MyCard from "./MyCard.vue";

@Component({
    name: "HelpCard",
    components: {
        MyCard,
    },
})
export default class HelpCard extends Vue {
    @Prop(String) private accountType!: string; // person: 个人号；company: 企业号
    private baseUrlIframe = process.env.VUE_APP_URL;
    // 使用指南
    private useGuide() {
        window.open(this.baseUrlIframe + '/doc/index.htm');
    }
    // 开发指南
    private developerGuide() {
        if (this.accountType === 'person') {
            // this.$router.push({ path: "/personDoc"}).catch((err) => err);
            window.open(this.baseUrlIframe + '/doc/docCenter.htm?docId=439&docTypeId=163');
        } else {
            // this.$router.push({ path: "/companyDoc"}).catch((err) => err);
            window.open(this.baseUrlIframe + '/doc/docCenter.htm');
        }
    }
    private vpnGuide() {
        window.open('https://ntalk.morewis.com/web/article/0028e50c-fe01-484f-b3e1-db0c51226821');
    }
}
