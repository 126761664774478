
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import MyCard from "./MyCard.vue";
import OpinionDialog from "@/components/RetainInfoDialog/OpinionDialog.vue";
@Component({
    name: "SystemCard",
    components: {
        MyCard,
        OpinionDialog,
    },
})
export default class SystemCard extends Vue {
    @Prop({
        type: Array,
        default: [],
    }) private bindCompany!: any; // 接收父组件传过来的值
    private baseUrlIframe = process.env.VUE_APP_URL;
    private switchCompanyList: any = [];
    private switchCompanyId = '';   // 切换企业的id
    private proList: any = [];
    private applyVisible: boolean = false;
    private perfectVisible: boolean = false;
    private opinionVisible: boolean = false;
    @Watch('bindCompany')
    private bindCompanyChange(val: any) {
        if (val.length !== 0) {
            this.switchCompanyId = val[0].companyId;
            this.switchCompany();
        }
    }
    private created() {
        if (this.bindCompany.length !== 0) {
            this.switchCompanyId = this.bindCompany[0].companyId;
            this.switchCompany();
        }
    }
    // 切换企业
    private switchCompany() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/individual/company/belongs/${this.switchCompanyId}/grouped-products`)
        .then((res: any) => {
            this.switchCompanyList = res;
            // 反馈弹窗传入的应用列表
            // this.proList = [];
            // res.map((system: any) => {
            //     if (system.products) {
            //         system.products.map((product: any) => {
            //             this.proList.push({
            //                 id: product.mcId,
            //                 name: product.name,
            //             });
            //         });
            //     }
            // });
        });
    }
    private bindMc(mcMask: any) {
        const url = this.$router.resolve({
            path: '/bindMc',
            query: {mcMask},
        });
        window.open(url.href, '_blank');
    }
    // 跳转绑定管理页面
    private goBind() {
        this.$router.push({ path: "/person/binding" }).catch((err) => err);
    }
    // 跳转链接
    private async openUrl(status: number, url: string) {
        if (status === -1) {
            this.$message.warning('服务器部署中!');
            return;
        }
        if (await this.$isLogin()) {
            window.open(url);
        } else {
            this.$alert('登录状态已过期，请重新登录', '提示', {
                showClose: false,
                confirmButtonText: '重新登录',
                callback: (action: any) => {
                    // 清除各种缓存数据
                    localStorage.clear();
                    // 跳转到登录页面
                    this.$router.push("/login").catch((err) => err);
                },
            });
        }
    }
    // 建议反馈
    private openOpinion() {
        this.opinionVisible = true;
    }
    private closeOpinion(num: number) {
        this.opinionVisible = false;
    }
}
