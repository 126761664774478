
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
    name: "EnlargeImg",
    components: {
    },
})
export default class EnlargeImg extends Vue {
    private enlargeSrc: string = "";
    private clientWidth: number = 0;
    private num: string = '';
    private imgWidth: string = "";
    private imgHeight: string = "";

    // 获取父组件传过来的图片路径
    @Prop(String) private childSrc!: string;
    // 获取父组件传过来的图片类型
    @Prop(String) private childNum!: string;

    // created
    private created() {
        this.$nextTick( () => {
            this.clientWidth = this.screenSize();
            this.openImg();
        });
    }
    // 判断当前屏幕大小
    private screenSize() {
        return document.body.clientWidth;
    }
    // 图片放大功能
    private openImg() {
        // 兼容1920,1600,1366分辨率
        if (this.clientWidth > 1600) {
            this.imgWidth = "600px";
            if (this.childNum === "1") {
                this.imgHeight = "750px";
            } else if (this.childNum === "2") {
                this.imgHeight = "360px";
            }
        } else if (this.clientWidth > 1366 && this.clientWidth <= 1600) {
            this.imgWidth = "500px";
            if (this.childNum === "1") {
                this.imgHeight = "600px";
            } else if (this.childNum === "2") {
                this.imgHeight = "260px";
            }
        } else {
            this.imgWidth = "400px";
            if (this.childNum === "1") {
                this.imgHeight = "500px";
            } else if (this.childNum === "2") {
                this.imgHeight = "200px";
            }
        }
    }
    // 给父组件传值
    @Emit('childImgFun')
    private closeTodo(): boolean {
        return false;
    }
    // 关闭图片放大
    private closeImgLarge() {
        this.enlargeSrc = "";
        this.closeTodo(); // 给父组件传值
    }
}
