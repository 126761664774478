
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
@Component({
    name: 'JoinCompanyDialog',
})
export default class JoinCompanyDialog extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private visible!: boolean; // 接收父组件传过来的值
    private inviteCode = "";    // 邀请码
    private staffName = ""; // 姓名

    @Watch('visible') // 监听父组件传过来的值
    private visibleFun(value: boolean) {
        if (value) {
            const useInfo = this.$store.state.userInfo;
            if (useInfo.realname && useInfo.realname !== '') {
                this.staffName = useInfo.realname;
            }
        }
    }

    @Emit('closeMsg')
    private closeTodo(num: number) {
        this.inviteCode = "";
        this.staffName = "";
        return num;
    }

    // 加入企业
    private subBind() {
        if (this.inviteCode === '' || this.inviteCode === null) {
            this.$confirm('请输入邀请码', '提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                type: 'warning',
            });
            return;
        }
        if (this.staffName === '' || this.staffName === null) {
            this.$confirm('请输入您的姓名', '提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                type: 'warning',
            });
            return;
        }
        this.$httpService.postData({inviteCode: this.inviteCode, staffName: this.staffName},
            '/apiProxy/api/frontend/individual/company/join').then((json: any) => {
            console.log(json);
            if (json) {
                this.$confirm('绑定成功！', '提示信息', {
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    type: 'success',
                }).then(() => {
                    // location.reload();
                    this.closeTodo(3);
                });
            } else {
                this.$confirm(json.message, '提示', {
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    type: 'info',
                });
                // vue.$Modal.info({
                //     title: "提示",
                //     content: json.message
                // });
            }
        });
    }
    private beforeClose(num: number) {  //
        this.closeTodo(num);
    }
}
