
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import MyCard from "./MyCard.vue";
import CreateCompanyDialog from "./CreateCompanyDialog.vue";
import JoinCompanyDialog from "./JoinCompanyDialog.vue";
import { setLocalAndSession } from '@/utils/utils';

@Component({
    name: "CompanyCard",
    components: {
        MyCard,
        CreateCompanyDialog,
        JoinCompanyDialog,
    },
})
export default class CompanyCard extends Vue {
    @Prop({
        type: Array,
        default: [],
    }) private bindCompany!: any; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private createdCompany!: any; // 接收父组件传过来的值
    private baseUrlIframe = process.env.VUE_APP_URL;
    private hasReverseAccount: boolean = false; // 是否可以切换个人企业中心
    private createCompanyFlag: boolean = false;
    private joinCompanyVisible: boolean = false;
    @Emit('reloadBelongCompany')// 重新获取已加入的企业列表
    private reloadBelongCompany() {
        return true;
    }
    @Emit('goReportCustomer')// 报备客户
    private goReportCustomer(row: any) {
        return row;
    }
    private handleCreateCompany() {
        this.createCompanyFlag = true;
    }
    private propCreateCompany(num: number) {
        this.createCompanyFlag = false;
        if (num === 1) {
            setTimeout(() => {
                // const personHomeIframe: any = document.getElementById('personHomeIframe');
                // personHomeIframe.contentWindow.location.reload();
                this.$router.go(0);
            }, 200);
        } else if (num === 2) {
            this.switchUser();
        }
    }
    private closeJoinCompany(num: number) {
        this.joinCompanyVisible = false;
        if (num === 3) {
            this.reloadBelongCompany();
        }
    }
    // 退出已加入的企业
    private unboundCompany(row: any) {
        this.$confirm(`确定要退出"<span style="color:#409eff">${row.companyName}</span>"吗？`, '退出已加入企业', {
            showClose: false,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
        }).then(() => {
            this.$httpService.postData({companyId: row.companyId}, `/apiProxy/api/frontend/individual/company/quit`)
                .then((res: any) => {
                    this.reloadBelongCompany();
                });
        });
    }
    // 切换用户中心
    private switchUser(): void {
        const userType = this.$store.state.userInfo.userType;
        this.$httpService.postData({}, this.$apiList.switcHover).then((res: any) => {
            // 获取当前用户信息
            this.$httpService.getData({type: 'getUserType'}, this.$apiList.userInfo)
            .then((resCurrent: any) => {
                // this.$store.commit('saveUserInfo', resCurrent);
                setLocalAndSession(resCurrent);

                if (resCurrent.userType === 1) {
                    this.$router.push("/person").catch((err) => err);
                } else {
                    this.$router.push("/company").catch((err) => err);
                }
            });
        });
    }
}
