<template>
    <div class="my_card">
        <div class="my_card_header" v-if="$slots.header || header">
            <slot name="header">{{ header }}</slot>
        </div>
        <div class="my_card_body" :style="bodyStyle">
            <slot></slot>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'MyCard',
    props: {
        header: {},
        bodyStyle: {},
    },
  };
</script>
<style lang="scss" scoped>
    .my_card {
        background-color: #FFF;
        // overflow: hidden;
        color: #303133;
        transition: .3s;
        margin-bottom: 15px;
        .my_card_header {
            padding: 12px 20px 0 34px;
            line-height: 30px;
            // border-bottom: 1px solid #EBEEF5;
            box-sizing: border-box;
            font-size: 16px;
            position: relative;
            &::before {
                content: "";
                width: 4px;
                height: 18px;
                background-color: #409eff;
                position: absolute;
                margin-top: 6px;
                margin-left: -14px;
            }
        }
        .my_card_body {
            padding: 14px 20px 20px;
        }
    }
</style>