
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import MyCard from "./MyCard.vue";
import ApplyDialog from "@/components/RetainInfoDialog/ApplyDialog.vue";
import ApplyConfirm from "@/components/RetainInfoDialog/ApplyConfirm.vue";
import PerfectDialog from "@/components/RetainInfoDialog/PerfectDialog.vue";
import ConfirmDialog from "@/components/RetainInfoDialog/ConfirmDialog.vue";

@Component({
    name: "HotProductCard",
    components: {
        MyCard,
        ApplyDialog,
        ApplyConfirm,
        PerfectDialog,
        ConfirmDialog,
    },
})
export default class HotProductCard extends Vue {
    @Prop(String) private accountType!: string; // person: 个人号；company: 企业号
    @Prop(Number) private certStatus!: number;
    private baseUrlIframe = process.env.VUE_APP_URL;
    private hotProduct: any = [];  // 热门产品
    private applyVisible: boolean = false;  // 试用前完善信息弹窗
    private perfectVisible: boolean = false;    // 体验前完善信息弹窗
    private applyConfirmFlg: boolean = false;  // 已认证企业确认试用弹窗
    private experienceItem: any = {};   // 当前体验产品
    private applyContent: object = {};
    private productInfo: any = {};
    private confirmFlg: boolean = false;
    private confirmMsg: any = '';
    private confirmTitle: any = null;
    private closeTitle: any = null;
    // 体验、试用成功后刷新体验、试用列表
    @Emit('remoteExperience')
    private remoteExperience() {
        return true;
    }
    private created() {
        this.initData();
    }
    // 初始化加载数据
    private initData() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/mall/recommend')
        .then((res: any) => {
            this.hotProduct = res;
        });
    }
    // 立即体验
    private experienceNow(row: any) {
        this.$httpService.postData({}, `/apiProxy/api/frontend/goods/${row.id}/try-experience`)
        .then((res: any) => {
            if (res.url) {
                this.remoteExperience();
                window.open(res.url);
            }
        }).catch((err: any) => {
            if (err.code === 1004004108) {
                this.experienceItem = row;
                this.perfectVisible = true;
            }
        });
    }
    private closeExperienceNow(num: number) {
        this.perfectVisible = false;
        if (num === 3) {
            this.remoteExperience();
            this.experienceNow(this.experienceItem);
        }
    }
    private applyCloseMsg(num: number) {
        this.applyConfirmFlg = false;
        if (num === 3) {
            this.applySubmit(this.applyContent);
        }
    }
    // 申请试用
    private applyTrial(row: any) {
        // 已认证企业用户
        if (this.accountType === 'company' && this.certStatus === 2) {
            this.applyConfirmFlg = true;
            this.applyContent = Object.assign({}, row);
            return;
        } else {
            this.applySubmit(row);
        }
        // this.$message({
        //     message: '重复提交，您提交的试用申请正在处理中，请勿重复提交',
        //     type: 'warning',
        // });
        // this.applyVisible = true;
    }
    // 提交申请
    private applySubmit(row: any) {
        this.$httpService.postData({}, `/apiProxy/api/frontend/goods/${row.id}/apply-trial`)
        .then((res: any) => {
            if (res.url) {
                this.remoteExperience();
                window.open(res.url);
            } else {
                this.remoteExperience();
                this.$confirm(res.message, '提示', {
                    confirmButtonText: '我知道了',
                    showCancelButton: false,
                });
            }
        }).catch((err: any) => {
            if (err.code === 1007000501) {  // 打开提交申请试用弹窗
                this.productInfo = row;
                this.applyVisible = true;
            }
        });
    }
    private closeApplyTrial(num: number) {
        this.applyVisible = false;
        if (num === 3) {
            this.initData();
            this.remoteExperience();
        }
    }
    // 前往商城
    private goMall() {
        // window.open(`${this.baseUrlIframe}/mall/index.htm`);
        this.$router.push('/mall');
    }
    // 立即购买
    private buyNow(row: any) {
        // this.$httpService.postData({}, `/apiProxy/api/frontend/goods/${row.id}/try-buy`)
        // .then((res: any) => {
        //     window.open(res.url);
        // });
        if (this.accountType === 'person') {    // 个人用户：提示切换企业用户
            this.confirmFlg = true;
            this.confirmMsg = '抱歉，暂不支持个人用户购买产品，如需购买请先创建企业并完成企业实名认证。';
            this.confirmTitle = '我知道了';
            this.closeTitle = null;
        } else if (this.accountType === 'company') {  // 企业用户：跳转商品详情
            if (this.certStatus === 2) {    // 判断企业是否已认证；certStatus：2 =》已认证
                // window.open(`${this.baseUrlIframe}/v2/goods/detail.htm?goodsId=${row.id}`);
                const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
                window.open(`${baseUrl}/goods/detail?goodsId=${row.id}&active=2`);
            } else if (this.certStatus === 0) { // certStatus：0 =》未认证
                this.confirmFlg = true;
                this.confirmMsg = '抱歉，暂不支持未认证的企业用户购买产品，请先完成企业实名认证。';
                this.confirmTitle = '前往认证';
            } else if (this.certStatus === 1) { // certStatus：1 =》审核中
                this.confirmFlg = true;
                this.confirmMsg = '您当前提交的实名认证正在审核中，预计将1-3个工作日完成。';
                this.confirmTitle = '认证审核中';
            } else if (this.certStatus === -1) { // certStatus：-1 =》认证不通过
                this.confirmFlg = true;
                this.confirmMsg = '您当前提交的实名认证未通过审核，请重新提交申请。';
                this.confirmTitle = '重新提交';
            }
        }
    }
    private confirmCloseMsg(val: any) {
        this.confirmFlg = false;
        if (val && this.accountType === 'company') {
            this.$router.push({
                path: "/company/Certification",
                query: { isBack: '0', isCert: '1' },
            }).catch((err) => err);
        }
    }
}
