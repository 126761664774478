
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: "NotFound",
})
export default class NotFound extends Vue {
    private backIndex() {
        const userType = this.$store.state.userInfo.userType;
        if (userType === 1) {
            this.$router.push("/person").catch((err) => err);
        } else if (userType === 2) {
            this.$router.push("/company").catch((err) => err);
        } else {
            this.$router.push("/index").catch((err) => err);
        }
    }
}
